import {useNuxtApp} from '#imports';
import type {Pinia} from 'pinia';
import {useAuth} from '../store/auth.store';

/**
 * ## Method : *useHasEnoughPriviledge (roles: string[])*
 *
 * Check if the user has all the priviledge to access a page
 * @param roles - The roles the user should have
 * @public-api
 *
 */
export const useHasEnoughPriviledge = async (roles: string[]): Promise<boolean> => {
    if (!roles) {
        return true;
    }

    // Composables
    const nuxtApp = useNuxtApp();

    // Stores
    const {$pinia} = nuxtApp;
    const store = useAuth($pinia as Pinia);

    if (!store.loggedIn) {
        if (!nuxtApp.$isPluginFetchCurrentSessionEnabled) {
            await store.loginOnce();
        } else {
            await store.checkIfLoggedOrIfNeedingRedirect();
        }
    }

    return store.loggedIn && store.hasEnoughPrivilege(roles);
};
